import React from 'react'
import { NewWindowLink } from 'components/Misc'

const SVG_WRAP = ({children, wh, style = {}, ...props}) => {
  if (wh !== undefined) {
    props.width = wh+'px'
    props.height = wh+'px'
  }
  props.style = style
  if (! props.style.verticalAlign) {
    props.style.verticalAlign = 'middle'
  }
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
      {children}
    </svg>
  )
}

export const Shuffle = ({wh='25', ...props}) => {
  props.id = 'svg_shuffle'
  props.alt= 'Random user search icon'
  props.wh = wh
  return (
    <SVG_WRAP viewBox="0 0 512 512" {...props}>
      <g>
        <path d="M409.434,82.96c30.04,25.7,60.08,51.42,89.971,77.29c-29.49,26.511-60.15,51.851-90.101,77.9
          c0.07-17.43-0.11-34.851,0.061-52.271c-19,0.03-38-0.109-56.99,0.061c-9.13,0.02-17.72,4.58-23.87,11.16
          c-54.55,56.779-109.21,113.46-163.74,170.26c-6.06,6.29-14.51,10.4-23.34,10.32c-34.689,0.22-69.39-0.17-104.069,0.229
          c-12.891-0.38-23.95-12.06-23.94-24.89c-0.64-13.91,11.62-25.9,25.28-26.311c27.55-0.26,55.13,0.15,82.68-0.189
          c10.05-0.061,18.9-5.92,25.24-13.32c53.72-55.939,107.5-111.83,161.25-167.75c6.04-6.59,14.72-10.65,23.68-10.85
          c25.939-0.11,51.89,0.1,77.82-0.101C409.313,117.32,409.174,100.141,409.434,82.96z"/>
        <path d="M32.764,134.48c37.5-0.311,75.131,0.03,112.69-0.08c9.68-0.43,19.54,3.2,26.12,10.43
          c15.609,16.061,31.05,32.311,46.57,48.46c6.359,6.5,9.85,15.96,7.64,24.98c-2.41,11.04-12.98,19.819-24.36,19.71
          c-7.729,0.35-14.85-3.59-20.54-8.48c-10.64-11.34-20.84-23.12-31.649-34.31c-6.19-6.3-15.09-9.41-23.83-9.29
          c-27.99-0.12-55.98,0.08-83.971-0.09c-11.14-0.34-23.02-5.601-27.13-16.66C7.974,155.07,17.654,137.19,32.764,134.48z"/>
        <path d="M261.554,278.17c9.67-7.729,24.83-6.609,33.36,2.32c11.689,11.98,23.13,24.21,34.83,36.18
          c6.08,6.41,14.84,10.07,23.67,9.98c18.66-0.07,37.319,0.08,55.99-0.09c-0.19-17.11-0.07-34.221-0.04-51.33
          c30.27,25.64,60.31,51.55,90.3,77.52c-29.95,25.95-60.061,51.71-90.221,77.41c-0.38-17.5,0.04-35.01-0.189-52.51
          c-25.97-0.24-51.94-0.021-77.91-0.11c-8.95-0.08-17.57-4.35-23.56-10.91c-16.73-17.27-33.73-34.27-50.29-51.689
          C247.904,304.471,250.034,286.36,261.554,278.17z"/>
      </g>
    </SVG_WRAP>
  )
}

export const QuestionMark = ({wh = '16', fill = '#828282', ...props}) => {
  props.id = 'qmark'
  props.alt= 'Help icon'
  props.wh = wh
  props.fill = fill
  return (
    <SVG_WRAP x="0px" y="0px" viewBox="0 0 367.1 373.3" {...props}>
      <path d="M183.3,365.5c99.3,0,179.8-80.5,179.8-179.8S282.6,5.9,183.3,5.9S3.5,86.4,3.5,185.7S84,365.5,183.3,365.5 z M183.3,329.5c-79.4,0-143.9-64.4-143.9-143.9S103.8,41.8,183.3,41.8s143.9,64.4,143.9,143.9S262.7,329.5,183.3,329.5z"/>
      <path d="M151.1,226.7v-12.5c0-10.8,2.3-20.2,6.9-28.2c4.6-8,13-16.5,25.3-25.5c11.8-8.4,19.6-15.3,23.4-20.6 c3.8-5.3,5.6-11.2,5.6-17.7c0-7.3-2.7-12.9-8.1-16.7c-5.4-3.8-12.9-5.7-22.6-5.7c-16.9,0-36.1,5.5-57.7,16.5l-18.4-36.9 c25.1-14.1,51.7-21.1,79.8-21.1c23.2,0,41.6,5.6,55.2,16.7c13.7,11.1,20.5,26,20.5,44.5c0,12.4-2.8,23-8.4,32 c-5.6,9-16.3,19.1-32,30.4c-10.8,8-17.6,14.1-20.5,18.2c-2.9,4.2-4.3,9.6-4.3,16.4v10.1H151.1z M145.7,284.3 c0-9.4,2.5-16.6,7.6-21.4c5.1-4.8,12.4-7.3,22.1-7.3c9.3,0,16.6,2.5,21.7,7.4c5.1,4.9,7.7,12,7.7,21.2c0,8.9-2.6,15.9-7.8,21 c-5.2,5.1-12.4,7.7-21.6,7.7c-9.4,0-16.8-2.5-21.9-7.5C148.3,300.5,145.7,293.4,145.7,284.3z"/>
    </SVG_WRAP>
  )
}

export const RestoreIcon = ({wh = '24', fill='white', ...props}) => {
  return (
    <SVG_WRAP viewBox="0 0 24 24" {...{wh, fill}} {...props}>
      <path d="M13.5 2c-5.621 0-10.211 4.443-10.475 10h-3.025l5 6.625 5-6.625h-2.975c.257-3.351 3.06-6 6.475-6 3.584 0 6.5 2.916 6.5 6.5s-2.916 6.5-6.5 6.5c-1.863 0-3.542-.793-4.728-2.053l-2.427 3.216c1.877 1.754 4.389 2.837 7.155 2.837 5.79 0 10.5-4.71 10.5-10.5s-4.71-10.5-10.5-10.5z"/>
    </SVG_WRAP>
  )
}


export const TwitterLink = ({children, ...props}) => {
  return (
    <NewWindowLink href='https://twitter.com/rhaksw' {...props}>
      {children}
    </NewWindowLink>
  )
}

export const TwitterWhite = ({wh='16', className, ...props}) => {
  return (
    <TwitterLink className={className}>
      <SVG_WRAP x="0px" y="0px" viewBox="0 0 400 400" {...{wh}} {...props}>
      <path style={{fill:'#FFFFFF'}} d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66"/>
      </SVG_WRAP>
    </TwitterLink>
  )
}

